export * from './flags';
export * from './network';
export * from './numbers';
export * from './points';
export * from './timeAndDates';
export * from './tokens';
export * from './apy';

const INFINITE_APPROVAL_HEX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const INFINITE_APPROVAL_OBJ = {
  _hex: INFINITE_APPROVAL_HEX,
  _isBigNumber: true,
};

export const CHARACTER_PIXELS_SPACE = 11;

export const PERMISSION_LEVELS = {
  PRIVATE: 0,
  FUNCTION_LEVEL: 1,
  POOL_LEVEL: 2,
  PUBLIC: 3,
};

export const POLLING_INTERVAL = 15000; // 15 seconds
