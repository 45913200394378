import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions } from '@apollo/client';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { sha256 } from 'crypto-hash';
import { API_URL } from 'Services/ApolloClient/constants';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// const persistedQueriesLink = createPersistedQueryLink({ sha256 });

const httpLink = new HttpLink({ uri: `${API_URL}/v2/graphql` });

// Enable persisted queries globally
// const v2Link = persistedQueriesLink.concat(httpLink);

export default new ApolloClient({
  cache: new InMemoryCache(),
  // link: v2Link,
  link: httpLink,
  connectToDevTools: true,
  defaultOptions,
  name: 'Syrup',
  version: '1.0',
});
