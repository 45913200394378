import { createContext, useState, useEffect } from 'react';
import useCookie from 'Hooks/useCookies';
import { useLocation } from 'react-router-dom';

type AppModalType = 'terms-conditions' | 'onboarding';

export interface AppModalContextType {
  openModal: (modal: AppModalType) => void;
  completeModal: (modal: AppModalType, value: string) => void;
  isModalOpen: (modal: AppModalType) => boolean;
}

export const AppModalContext = createContext<AppModalContextType | undefined>(undefined);

export const AppModalProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [openModals, setOpenModals] = useState<AppModalType[]>([]);
  const { cookie: termsCookieSet, setCookie: setTermsCookie } = useCookie('terms-conditions');
  const { cookie: onboardingCookieSet, setCookie: setOnboardingCookie } = useCookie('onboarding');

  useEffect(() => {
    if (!termsCookieSet) {
      setOpenModals(['terms-conditions']);
    } else if (!onboardingCookieSet && pathname === '/lend') {
      setOpenModals(['onboarding']);
    }
  }, [pathname, termsCookieSet, onboardingCookieSet]);

  const openModal = (modal: AppModalType) => {
    setOpenModals(prev => [...prev, modal]);
  };

  const completeModal = (modal: AppModalType, value: string) => {
    setOpenModals(prev => prev.filter(m => m !== modal));

    if (modal === 'terms-conditions') setTermsCookie(value);
    if (modal === 'onboarding') setOnboardingCookie(value);
  };

  const isModalOpen = (modal: AppModalType): boolean => {
    return openModals.includes(modal);
  };

  return (
    <AppModalContext.Provider value={{ openModal, completeModal, isModalOpen }}>{children}</AppModalContext.Provider>
  );
};
