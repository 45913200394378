import { CustomSizeScale, CustomShapeScale } from 'Theme/types';

export const syrupShadows: CustomSizeScale = {
  xs: '0px 1px 2px 0px rgba(228, 229, 231, 0.24)',
  sm: '0px 2px 4px 0px rgba(27, 28, 29, 0.04)',
  md: '0px 16px 32px -12px rgba(88, 92, 95, 0.1)',
  lg: '0px 16px 40px -8px rgba(88, 92, 95, 0.16)',
  xl: '0px 24px 56px -4px rgba(88, 92, 95, 0.16)',
  xxl: '0px 40px 96px -8px rgba(88, 92, 95, 0.2)',
};

export const shape: CustomShapeScale = {
  round: '50%',
  sm: '8px',
  md: '12px',
  lg: '20px',
  xl: '32px',
  xxl: '40px',
};

export const zIndex = {
  low: 10,
  medium: 20,
  high: 30,
};
