import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';

// Components
import MapleDialog from 'Components/ds/MapleDialog';
import MapleTypography from 'Components/ds/MapleTypography';

// Types
import { ReferralErrors } from 'Context/Referral/Referral';
import MapleButton from './ds/MapleButton';

import { ReactComponent as CrossIcon } from 'Assets/icon-referral-cross.svg';
import { ReactComponent as CautionIcon } from 'Assets/icon-referral-caution.svg';

const ErrorCopyMap: Record<ReferralErrors, { heading; copy: string; icon: ReactNode }> = {
  notActive: {
    heading: 'Invalid Referral Token',
    copy: 'Referral code is not active.',
    icon: <CrossIcon />,
  },
  alreadyLender: {
    heading: 'This wallet is not eligible for referral.',
    copy: 'You have already deposited funds into Syrup.',
    icon: <CautionIcon />,
  },
  alreadyReferred: {
    heading: 'This wallet is not eligible for referral.',
    copy: 'You have already linked a different referral token to this account.',
    icon: <CrossIcon />,
  },
};

const ReferralErrorModal = ({ error, handleClose }: { error: ReferralErrors; handleClose: () => void }) => {
  const { heading, copy, icon } = ErrorCopyMap[error];

  return (
    <MapleDialog open onClose={handleClose}>
      <Stack direction='column' alignItems='center' textAlign='center' spacing={3} sx={{ p: 3 }}>
        {icon}
        <MapleTypography variant='h5' color='text.strong'>
          {heading}
        </MapleTypography>
        <MapleTypography variant='paragraph' size='medium' color='text.soft'>
          {copy}
        </MapleTypography>

        <MapleButton variant='contained' fullWidth onClick={handleClose}>
          Got it
        </MapleButton>
      </Stack>
    </MapleDialog>
  );
};

export default ReferralErrorModal;
