const AgrandirTextBoldSrc = [
  `url('https://downloads.eth.maple.finance/fonts/Agrandir-TextBold.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/Agrandir-TextBold.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/Agrandir-TextBold.woff2') format('woff2')`,
];

const InterRegularSrc = `url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap')`;
const InterMediumSrc = `url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap')`;

const CssBaseline = () => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [
          {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontDisplay: 'swap',
            src: InterRegularSrc,
          },
          {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontDisplay: 'swap',
            src: InterMediumSrc,
          },
          {
            fontFamily: 'Agrandir Text Bold',
            src: AgrandirTextBoldSrc.join(', '),
            fontWeight: 'bold',
            fontDisplay: 'block',
          },
        ],
        ':root': {
          fontFamily: 'Inter, Agrandir Text Bold, Helvetica Neue, Helvetica, Arial, sans-serif',
          colorScheme: 'light dark',
          fontSynthesis: 'none',
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        html: {
          fontSize: '62.5%', // 10px
        },
        body: {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          display: 'flex',
          minWidth: '320px',
          minHeight: '100vh',
        },
      },
    },
  };
};

export default CssBaseline;
