import { FunctionComponent, SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  RiArrowGoBackFill,
  RiArrowLeftLine,
  RiArrowRightUpLine,
  RiArrowRightLine,
  RiArrowRightDownLine,
  RemixiconComponentType,
  RiCoinsFill,
  RiPercentLine,
  RiSendPlaneFill,
  RiFundsFill,
  RiDvdFill,
  RiSwapFill,
  RiCheckboxCircleLine,
  RiTwitterXFill,
  RiErrorWarningLine,
  RiSubtractFill,
  RiAddFill,
  RiAlertFill,
  RiArrowDownSFill,
  RiArrowRightSFill,
  RiCloseLine,
  RiCloseFill,
  RiMenu5Line,
  RiHandCoinFill,
  RiWallet2Line,
  RiArrowDownLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiStackLine,
  RiHandHeartFill,
  RiPresentationFill,
} from '@remixicon/react';

// Custom Icons

import { ReactComponent as IconSyrupBottleLow } from 'Assets/icon-syrup-bottle-low.svg';
import { ReactComponent as IconSyrupBottleHalf } from 'Assets/icon-syrup-bottle-half.svg';
import { ReactComponent as IconSyrupBottleFull } from 'Assets/icon-syrup-bottle-full.svg';
import { ReactComponent as IconSyrupBottleContained } from 'Assets/icon-syrup-bottle-contained.svg';
import { ReactComponent as IconSyrup } from 'Assets/icon-syrup.svg';
import { ReactComponent as IconBullet } from 'Assets/icon-bullet.svg';
import { ReactComponent as IconTelegram } from 'Assets/icon-telegram.svg';
import { ReactComponent as IconGlobal } from 'Assets/icon-globe.svg';
import { ReactComponent as IconGas } from 'Assets/icon-gas.svg';
import { ReactComponent as IconEthereum } from 'Assets/icon-ethereum.svg';
import { ReactComponent as IconReferralLink } from 'Assets/icon-referral-link.svg';
import { ReactComponent as IconExodusWallet } from 'Assets/icon-exodus-wallet.svg';
import { ReactComponent as IconOkexWallet } from 'Assets/icon-okex-wallet.svg';
import { ReactComponent as IconEthCCLogo } from 'Assets/icon-ethcc-logo.svg';

// Asset Icons
import { ReactComponent as IconUSDC } from 'Assets/icon-usdc.svg';
import { ReactComponent as IconUSDT } from 'Assets/icon-usdt.svg';
import { ReactComponent as IconAAVE } from 'Assets/icon-aave.svg';
import { ReactComponent as IconWETH } from 'Assets/icon-weth.svg';
import { ReactComponent as IconLINK } from 'Assets/icon-link.svg';
import { ReactComponent as IconDAI } from 'Assets/icon-dai.svg';
import { ReactComponent as IconWBTC } from 'Assets/icon-wbtc.svg';
import { ReactComponent as IconETH } from 'Assets/icon-eth.svg';
import { ReactComponent as IconINJ } from 'Assets/icon-inj.svg';
import { ReactComponent as IconBTC } from 'Assets/icon-btc.svg';
import { ReactComponent as IconGBTC } from 'Assets/icon-gbtc.svg';
import { ReactComponent as IconSOL } from 'Assets/icon-sol.svg';
import { ReactComponent as IconWstETH } from 'Assets/icon-wstEth.svg';
import { ReactComponent as IconPTsUSDe } from 'Assets/icon-pt-susde.svg';
import { ReactComponent as IconADA } from 'Assets/icon-ada.svg';
import { ReactComponent as IconAVAX } from 'Assets/icon-avax.svg';
import { ReactComponent as IconBNB } from 'Assets/icon-bnb.svg';
import { ReactComponent as IconFIL } from 'Assets/icon-fil.svg';
import { ReactComponent as IconXRP } from 'Assets/icon-xrp.svg';
import { ReactComponent as IconORCALPPYUSDC } from 'Assets/icon-orca-lp-usdc.svg';
import { ReactComponent as IconTBills } from 'Assets/icon-t-bills.svg';
import { ReactComponent as IconUSDCUSDT } from 'Assets/icon-usdc-usdt.svg';
import { ReactComponent as IconDripsDeposits } from 'Assets/icon-drips-deposits.svg';

type LibraryIcons =
  | 'add'
  | 'alertFill'
  | 'arrowDownSFill'
  | 'arrowDownSLine'
  | 'arrowDown'
  | 'arrowGoBackFill'
  | 'arrowLeftLine'
  | 'arrowRightUp'
  | 'arrowRightDown'
  | 'arrowRight'
  | 'arrowRightSFill'
  | 'arrowUpSLine'
  | 'checkCircle'
  | 'closeLine'
  | 'closeFill'
  | 'coins'
  | 'coinsFill'
  | 'dvdFill'
  | 'percentLine'
  | 'handCoinFill'
  | 'handHeartFill'
  | 'errorWarningLine'
  | 'fundsFill'
  | 'menuLine'
  | 'presentationFill'
  | 'sendPlane'
  | 'subtract'
  | 'stackLine'
  | 'swapFill'
  | 'wallet2Line'
  | 'xFill';

type CustomIcons =
  | 'bulletPoint'
  | 'dripsDeposits'
  | 'ethereum'
  | 'ethCC'
  | 'gas'
  | 'global'
  | 'referralLink'
  | 'syrupBottleLow'
  | 'syrupBottleHalf'
  | 'syrupBottleFull'
  | 'syrupBottleContained'
  | 'syrup'
  | 'telegram'
  | 'walletExodus'
  | 'walletOkex';

type AssetIcons =
  | 'usdc'
  | 'usdt'
  | 'aave'
  | 'weth'
  | 'link'
  | 'dai'
  | 'wbtc'
  | 'eth'
  | 'inj'
  | 'btc'
  | 'gbtc'
  | 'sol'
  | 'wstEth'
  | 'pt_susde'
  | 'ada'
  | 'avax'
  | 'bnb'
  | 'fil'
  | 'xrp'
  | 'orcalp_pyusdc'
  | 't-bills'
  | 'usdc-usdt';

export type Icons = LibraryIcons | CustomIcons | AssetIcons;

type IconComponentType = RemixiconComponentType | FunctionComponent<SVGProps<SVGSVGElement>>;

const libraryIcons: Record<LibraryIcons, RemixiconComponentType> = {
  add: RiAddFill,
  alertFill: RiAlertFill,
  arrowDownSFill: RiArrowDownSFill,
  arrowDownSLine: RiArrowDownSLine,
  arrowDown: RiArrowDownLine,
  arrowGoBackFill: RiArrowGoBackFill,
  arrowLeftLine: RiArrowLeftLine,
  arrowRightUp: RiArrowRightUpLine,
  arrowRightDown: RiArrowRightDownLine,
  arrowRight: RiArrowRightLine,
  arrowRightSFill: RiArrowRightSFill,
  arrowUpSLine: RiArrowUpSLine,
  checkCircle: RiCheckboxCircleLine,
  closeLine: RiCloseLine,
  closeFill: RiCloseFill,
  coins: RiCoinsFill,
  coinsFill: RiCoinsFill,
  dvdFill: RiDvdFill,
  percentLine: RiPercentLine,
  handCoinFill: RiHandCoinFill,
  handHeartFill: RiHandHeartFill,
  errorWarningLine: RiErrorWarningLine,
  fundsFill: RiFundsFill,
  menuLine: RiMenu5Line,
  presentationFill: RiPresentationFill,
  subtract: RiSubtractFill,
  sendPlane: RiSendPlaneFill,
  stackLine: RiStackLine,
  swapFill: RiSwapFill,
  wallet2Line: RiWallet2Line,
  xFill: RiTwitterXFill,
};

const customIcons: Record<CustomIcons, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  bulletPoint: IconBullet,
  dripsDeposits: IconDripsDeposits,
  gas: IconGas,
  global: IconGlobal,
  ethereum: IconEthereum,
  ethCC: IconEthCCLogo,
  referralLink: IconReferralLink,
  syrup: IconSyrup,
  syrupBottleLow: IconSyrupBottleLow,
  syrupBottleHalf: IconSyrupBottleHalf,
  syrupBottleFull: IconSyrupBottleFull,
  syrupBottleContained: IconSyrupBottleContained,
  telegram: IconTelegram,
  walletExodus: IconExodusWallet,
  walletOkex: IconOkexWallet,
};

const assetIcons: Record<AssetIcons, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  usdc: IconUSDC,
  usdt: IconUSDT,
  aave: IconAAVE,
  weth: IconWETH,
  link: IconLINK,
  dai: IconDAI,
  wbtc: IconWBTC,
  eth: IconETH,
  inj: IconINJ,
  btc: IconBTC,
  gbtc: IconGBTC,
  sol: IconSOL,
  wstEth: IconWstETH,
  pt_susde: IconPTsUSDe,
  ada: IconADA,
  avax: IconAVAX,
  bnb: IconBNB,
  fil: IconFIL,
  xrp: IconXRP,
  orcalp_pyusdc: IconORCALPPYUSDC,
  't-bills': IconTBills,
  'usdc-usdt': IconUSDCUSDT,
};

const iconMap: Record<Icons, IconComponentType> = {
  ...libraryIcons,
  ...customIcons,
  ...assetIcons,
};

export interface MapleIconProps extends SVGProps<SVGSVGElement> {
  icon: Icons;
  color?: string; // "primary.main" or {theme.palette.primary.main}
  size?: string | number;
}

const isValidColor = (color: string) => {
  const hexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const rgbPattern = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
  const rgbaPattern = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(0|1|0\.\d+)\s*\)$/;

  return hexPattern.test(color) || rgbPattern.test(color) || rgbaPattern.test(color);
};

const MapleIcon = ({ icon, color = 'icon.strong', size = '24px', ...rest }: MapleIconProps) => {
  const theme = useTheme();
  const IconComponent = iconMap[icon];

  if (!IconComponent) {
    console.warn(`Icon '${icon}' not found in the iconMap.`);
    return null;
  }

  const getIconColor = () => {
    if (isValidColor(color)) return color;

    const [name, variant] = color.split('.');

    return theme.palette[name][variant];
  };

  const iconColor = getIconColor();

  const isRemixIcon = (icon: IconComponentType): icon is RemixiconComponentType => {
    // Check if the icon is of type RemixiconComponentType
    return typeof icon === 'function' && icon.name.startsWith('Ri');
  };

  const commonProps = {
    ...rest,
    color: iconColor,
    width: rest.width || size,
    height: rest.height || size,
  };

  if (isRemixIcon(IconComponent)) {
    // Cast rest to exclude children prop to satisfy RemixiconComponentType
    const { ...remixIconProps } = rest as Omit<SVGProps<SVGSVGElement>, 'children'>;
    return <IconComponent {...remixIconProps} color={iconColor} size={size} />;
  }

  return <IconComponent {...commonProps} fill={iconColor} />;
};

export default MapleIcon;
