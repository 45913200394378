import { ReactNode, createContext, useEffect, useState } from 'react';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage.ts';

// GraphQL
import { useIsRestrictedLazyQuery } from 'Graphql/schema';

export interface RestrictedContextType {
  isRestricted: boolean;
  setRestricted: (value: boolean) => void;
}
export const RestrictedContext = createContext<RestrictedContextType>({
  isRestricted: false,
  setRestricted: () => {},
});

type Props = {
  children: ReactNode;
};

export const RestrictedProvider = ({ children }: Props) => {
  const isMarketingPage = useIsMarketingPage();
  const [isRestricted, setRestricted] = useState(false);
  const [getIsRestricted, { error }] = useIsRestrictedLazyQuery();

  useEffect(() => {
    if (!isMarketingPage && !isRestricted && error?.graphQLErrors[0]?.extensions.code === 'RESTRICTED')
      setRestricted(true);
  }, [error, isRestricted, isMarketingPage]);

  useEffect(() => {
    if (isMarketingPage) {
      setRestricted(false);
    } else {
      getIsRestricted();
    }
  }, [isMarketingPage, getIsRestricted]);

  return <RestrictedContext.Provider value={{ isRestricted, setRestricted }}>{children}</RestrictedContext.Provider>;
};
