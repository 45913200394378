import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import App from './App.tsx';

import './polyfills.js';

Sentry.init({
  denyUrls: [/localhost/, /https:\/\/www\.googletagmanager\.com\/gtag\/js\?id=.*/],
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_PROJECT ?? 'unknown',
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.01, // 1% of normal traffic
  replaysOnErrorSampleRate: 1.0, // 100% of errors
  beforeSend(event, hint) {
    const exception = hint.originalException;

    if (exception instanceof Error && exception.stack) {
      const stacktrace = exception.stack;

      // Ignore if stacktrace contains 'node_modules'
      if (/node_modules/.test(stacktrace)) {
        return null; // Discard the event
      }
    }

    // Continue to send the event if it doesn't match the above criteria
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
