import { ConnectButton } from '@rainbow-me/rainbowkit';
import Stack from '@mui/material/Stack';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// Components
import MapleButton from './ds/MapleButton';
import MapleIcon from './ds/MapleIcon';

const ConnectWalletButton = () => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const connected = mounted && account && chain;

        const handleOpenConnectModal = ({ target }) => {
          target.blur();
          openConnectModal();
        };

        const handleOpenAccountModal = ({ target }) => {
          target.blur();
          openAccountModal();
        };

        return (
          <Stack direction='row' spacing={1}>
            {(() => {
              if (!connected) {
                return (
                  <MapleButton onClick={handleOpenConnectModal} size='xSmall'>
                    Connect Wallet
                  </MapleButton>
                );
              }

              if (chain.unsupported) {
                return (
                  <MapleButton onClick={openChainModal} size='xSmall'>
                    Wrong network
                  </MapleButton>
                );
              }

              return (
                <>
                  <MapleIcon icon='ethereum' width={36} height={36} color='icon.weak' />
                  <MapleButton
                    onClick={handleOpenAccountModal}
                    size='small'
                    color='secondary'
                    startIcon={<Jazzicon diameter={16} seed={jsNumberForAddress(account.address)} />}
                  >
                    {account.displayName}
                    {account.ensAvatar}
                  </MapleButton>
                </>
              );
            })()}
          </Stack>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWalletButton;
