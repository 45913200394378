import { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Icons
import { ReactComponent as NavIndicator } from 'Assets/icon-nav-indicator.svg';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

const navItems = {
  lend: {
    title: 'Lend',
    url: '/lend',
  },
  portfolio: {
    title: 'Portfolio',
    url: '/portfolio',
  },
  drips: {
    title: 'Drips',
    url: '/drips',
  },
  details: {
    title: 'Details',
    url: '/details',
  },
};

const container = {
  position: 'fixed',
  transform: theme => ({ xs: 'translate(0px, 0px)', md: `translate(-50% ,${theme.spacing(5.2)})` }),
  left: { xs: 0, md: '50%' },
  bottom: theme => ({ xs: theme.spacing(2.5), md: 'auto' }),
  zIndex: 9, // @TODO set custom zIndex into theme
  px: theme => `${theme.spacing(2)} !important`,
};

const stack = {
  backgroundColor: { xs: 'background.white', md: 'transparent' },
  boxShadow: theme => ({ xs: theme.syrupShadows.sm, md: 'none' }),
  py: { xs: 2, md: 0 },
  borderRadius: theme => theme.shape.xxl,
};

const link = {
  transform: 'translateX(0px)',
  transition: 'transform 400ms ease',

  a: {
    textDecoration: 'none',
    fontFamily: theme => theme.typography.label.fontFamily,
    fontWeight: theme => theme.typography.label.fontWeight,
    letterSpacing: theme => theme.typography.label.medium.letterSpacing,
    fontSize: theme => theme.typography.label.medium.fontSize,
    lineHeight: theme => theme.typography.label.medium.lineHeight,
    color: theme => theme.palette.text.soft,
    transitionDelay: '200ms',
    transition: 'transform 500ms ease',
    '&:hover': {
      color: theme => theme.palette.text.strong,
    },
  },

  '&.selected': {
    transitionDelay: '0ms',
    transform: 'translateX(12px)',
    a: {
      color: theme => theme.palette.text.strong,
    },
  },
};

const navIndicatorStyles = {
  position: 'absolute',
  top: '6px',
  left: '-2px',
  opacity: 0,
  transitionDelay: '200ms',
  transition: 'opacity 400ms ease',

  '&.selected': {
    transitionDelay: '0ms',
    opacity: 1,
  },
};

const ROUTES_WITH_NVIGATION_BLOCKED = ['/restricted'];

const NavBar = () => {
  const { pathname } = useLocation();
  const { isDesktopDevice } = useBreakpoint();

  const maxWidth = isDesktopDevice ? 'sm' : 'md';
  const isNavigationBlocked = useMemo(() => ROUTES_WITH_NVIGATION_BLOCKED.includes(pathname), [pathname]);

  return (
    <Container maxWidth={maxWidth} sx={container}>
      <Stack direction='row' alignItems='center' justifyContent='center' spacing={4} sx={stack}>
        {Object.keys(navItems).map(page => (
          <Box key={navItems[page].title} sx={{ position: 'relative' }}>
            <Box sx={navIndicatorStyles} className={pathname === navItems[page].url ? 'selected' : ''}>
              <NavIndicator />
            </Box>
            <Box sx={link} className={pathname === navItems[page].url ? 'selected' : ''}>
              <Link to={isNavigationBlocked ? undefined : navItems[page].url}>{navItems[page].title}</Link>
            </Box>
          </Box>
        ))}
      </Stack>
    </Container>
  );
};

export default NavBar;
