import React, { useMemo, createContext, useEffect, useState } from 'react';
import { AnalyticsBrowser, ID } from '@segment/analytics-next';

export interface AnalyticsContextType {
  analytics: AnalyticsBrowser | null;
  initialized: boolean;
  anonymousId: ID;
}
export const AnalyticsContext = createContext<AnalyticsContextType>({
  analytics: null,
  initialized: false,
  anonymousId: null,
});

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [anonymousId, setAnonymousId] = useState<ID>(null);
  const analytics = useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  useEffect(() => {
    if (analytics) {
      const setIdFromAnalytics = async () => {
        const anonymousId = (await analytics.user()).anonymousId();
        setAnonymousId(anonymousId);
      };

      analytics.ready().then(() => {
        setInitialized(true);
        setIdFromAnalytics();
      });
    }
  }, [analytics]);

  return (
    <AnalyticsContext.Provider value={{ analytics, initialized, anonymousId }}>{children}</AnalyticsContext.Provider>
  );
};
