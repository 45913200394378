import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { isUSDTEnabled as defaultUSDTEnabled } from 'Constants/flags';

export const useFeatureFlags = () => {
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);

  // Extract usdt parameter
  const usdtParam = queryParams.get('usdt');

  // Track if isUSDTEnabled was ever true
  const hasBeenEnabled = useRef(false);

  // Initialize the state, but only set to true once
  const [isUSDTEnabled, setIsUSDTEnabled] = useState(() => {
    const enabled = usdtParam !== null || defaultUSDTEnabled;
    if (enabled) {
      hasBeenEnabled.current = true;
    }
    return enabled;
  });

  useEffect(() => {
    // If isUSDTEnabled is already true, don't change it
    if (!isUSDTEnabled && usdtParam !== null) {
      setIsUSDTEnabled(true);
      hasBeenEnabled.current = true;
    }
  }, [usdtParam, isUSDTEnabled]);

  useEffect(() => {
    // Ensure that once isUSDTEnabled is true, it never goes back to false
    if (hasBeenEnabled.current) {
      setIsUSDTEnabled(true);
    }
  }, [isUSDTEnabled]);

  return {
    isUSDTEnabled,
  };
};
