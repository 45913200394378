import { createContext, useState, useEffect, ReactNode, ReactElement, useMemo } from 'react';
import { useAccount } from 'wagmi';
import {
  ReferralTokenFragment,
  useGetReferralTokenQuery,
  useApplyReferralTokenMutation,
  useGetReferralDataQuery,
} from 'Graphql/schema';
import { isReferralEnabled } from 'Constants/flags';
import ReferralErrorModal from 'Components/ReferralErrorModal';

export type ReferralErrors = 'notActive' | 'alreadyLender' | 'alreadyReferred';

export interface ReferralContextType {
  referralToken?: ReferralTokenFragment | null;
  error?: string;
  isLoading: boolean;
}

const defaultReferralContext: ReferralContextType = {
  referralToken: null,
  error: undefined,
  isLoading: false,
};

const formatToken = (token: ReferralTokenFragment) => {
  return { ...token, boost: +(token.boost * 100).toFixed(2) };
};

export const ReferralContext = createContext<ReferralContextType>(defaultReferralContext);

export interface ReferralProps {
  children: ReactNode;
}

export const ReferralProvider = ({ children }: ReferralProps): ReactElement => {
  const accountData = useAccount();
  const address = accountData?.address?.toLowerCase();

  // State
  const [pendingTokenString, setPendingTokenString] = useState<string | null>();
  const [pendingToken, setPendingToken] = useState<ReferralTokenFragment | null>();
  const [walletToken, setWalletToken] = useState<ReferralTokenFragment | null>();
  const [isSyrupLender, setIsSyrupLender] = useState<boolean>(false);
  const [hasDismissedModal, setHasDismissedModal] = useState(false);

  // Queries and Mutations
  const { data: pendingReferralData, loading: pendingLoading } = useGetReferralTokenQuery({
    variables: { token: pendingTokenString || '' },
    skip: !pendingTokenString || !isReferralEnabled,
  });
  const {
    data: referralData,
    loading: referralDataLoading,
    refetch: refetchReferralData,
  } = useGetReferralDataQuery({
    variables: { address: address || '', account: address || '' },
    skip: !address || !isReferralEnabled,
  });

  const [applyReferralToken, { error: mutationError }] = useApplyReferralTokenMutation();

  const applyTokenToWallet = async (token: string, address: string) => {
    try {
      const { data } = await applyReferralToken({
        variables: { input: { token, refereeAddress: address } },
      });
      if (data?.applyReferralToken?.success) {
        localStorage.removeItem('pendingReferralToken');
        await refetchReferralData();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);
    }
  };

  const isLoading = useMemo(() => pendingLoading || referralDataLoading, [pendingLoading, referralDataLoading]);

  const error = useMemo((): ReferralErrors | undefined => {
    if (hasDismissedModal) return;
    if (isLoading) return;
    if (!pendingTokenString) return;

    if (!pendingToken?.isActive) {
      return 'notActive';
    }

    if (!walletToken && isSyrupLender) {
      return 'alreadyLender';
    }

    if (walletToken && pendingTokenString !== walletToken.token) {
      return 'alreadyReferred';
    }
  }, [mutationError, isLoading, pendingToken, pendingTokenString, walletToken, isSyrupLender, hasDismissedModal]);

  const deleteAndReset = () => {
    setHasDismissedModal(true);
    localStorage.removeItem('pendingReferralToken');
    setPendingToken(undefined);
    setPendingTokenString(null);
  };

  // Main Logic
  useEffect(() => {
    if (!address) return;

    if (isLoading) return;

    if (walletToken) return;

    if (isSyrupLender) return;

    if (!pendingToken?.isActive) return;

    if (pendingTokenString) {
      applyTokenToWallet(pendingTokenString, address);
    }
  }, [address, walletToken, pendingTokenString, isSyrupLender, pendingToken, isLoading]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('referral');
    const tokenFromStorage = localStorage.getItem('pendingReferralToken');

    if (tokenFromUrl) {
      setPendingTokenString(tokenFromUrl);
      localStorage.setItem('pendingReferralToken', tokenFromUrl);
    } else if (tokenFromStorage) {
      setPendingTokenString(tokenFromStorage);
    }
  }, []);

  useEffect(() => {
    // loading
    if (pendingLoading) return;

    // reset
    if (!pendingReferralData) {
      setPendingToken(null);
      return;
    }

    // init
    const formattedToken = formatToken(pendingReferralData.getReferralToken);
    setPendingToken(formattedToken);
  }, [pendingReferralData, pendingLoading]);

  useEffect(() => {
    // loading
    if (referralDataLoading) return;

    // reset
    if (!referralData) {
      setWalletToken(null);
      setIsSyrupLender(false);
      return;
    }

    // init
    if (referralData.getWalletReferralToken) {
      const formattedToken = formatToken(referralData.getWalletReferralToken);
      setWalletToken(formattedToken);
    }

    setIsSyrupLender(!!referralData.account?.isSyrupLender);
  }, [referralData, referralDataLoading]);

  return (
    <ReferralContext.Provider
      value={{
        referralToken: walletToken || pendingToken,
        error,
        isLoading,
      }}
    >
      {error && <ReferralErrorModal error={error} handleClose={deleteAndReset} />}
      {children}
    </ReferralContext.Provider>
  );
};
