import { useState } from 'react';
import Cookies from 'js-cookie';

export default function useCookie(name) {
  const [cookie, setCookieVal] = useState(() => Cookies.get(name) || '');

  const setCookie = (newValue: string, expires: number = 30) => {
    const options: Cookies.CookieAttributes = {
      expires,
      sameSite: 'None', // Allows cross-site cookie usage
      secure: true, // Ensure cookies are set securely
    };
    Cookies.set(name, newValue, options);
    setCookieVal(newValue);
  };

  const deleteCookie = () => {
    Cookies.remove(name);
    setCookieVal('');
  };

  return {
    cookie,
    setCookie,
    deleteCookie,
  };
}
