import { ReactElement, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { watchAccount } from '@wagmi/core';
import { Address } from 'viem';

import {
  AssetFragment,
  FunctionLevelBitmapFragment,
  GetAccountQuery,
  GetGlobalSyrupDataQuery,
  PoolFragment,
  useGetAccountQuery,
  useGetGlobalSyrupDataQuery,
} from 'Graphql/schema';

// Context
import { ClientContext } from 'Context/Client';

// Constants
import { APY_DECIMALS, PERCENTAGE_UI_DECIMALS, POLLING_INTERVAL, USDC_DECIMALS, config } from 'Constants';

// Hooks
import { useFeatureFlags } from 'Hooks/useFeatureFlags';

// Utils
import { accountHasPermission } from 'Utils/account';
import { defaultAssetData, defaultPoolData, defaultValueInterface } from 'Utils/defaultValues';
import {
  buildPercentageValueInterfaceFromBigNumber,
  buildValueInterfaceFromBigNumberValues,
  EMPTY_PERCENTAGE,
  ValueInterface,
} from 'Utils/valueInterface';

type PermissionsMap = Record<FunctionIDs, boolean>;
type PermissionsMapById = Record<Address, PermissionsMap>;

type FunctionIDs =
  | 'P:deposit'
  | 'P:depositWithPermit'
  | 'P:mint'
  | 'P:mintWithPermit'
  | 'P:redeem'
  | 'P:removeShares'
  | 'P:requestRedeem'
  | 'P:requestWithdraw'
  | 'P:withdraw';

const defaultPermissionsMap: PermissionsMap = {
  'P:deposit': false,
  'P:depositWithPermit': false,
  'P:mint': false,
  'P:mintWithPermit': false,
  'P:redeem': false,
  'P:removeShares': false,
  'P:requestRedeem': false,
  'P:requestWithdraw': false,
  'P:withdraw': false,
};

const defaultPermissionsMapById: Record<Address, PermissionsMap> = {
  '0x00000': { ...defaultPermissionsMap },
  '0x00001': { ...defaultPermissionsMap },
};

type Contracts = 'poolUSDC' | 'usdc' | 'routerUSDC' | 'poolUSDT' | 'usdt' | 'routerUSDT';

const defaultAddresses: Record<Contracts, Address | null> = {
  poolUSDC: null,
  usdc: null,
  routerUSDC: null,
  poolUSDT: null,
  usdt: null,
  routerUSDT: null,
};

interface GlobalData {
  pools: PoolFragment[];
  lendingAssets: AssetFragment[];
  addresses: Record<Contracts, Address | null>;

  weightedApy: ValueInterface;
  weightedTvl: ValueInterface;
}

interface AccountData {
  permissionsMapByPoolId: PermissionsMapById;
}

interface ContextData extends GlobalData, AccountData {
  accountLoading: boolean;
  syrupPoolLoading: boolean;
  isUSDTEnabled: boolean;
  refetchAccount: () => void;
  refetchGlobal: () => void;
}

const defaultGlobalData: GlobalData = {
  pools: [{ ...defaultPoolData }],
  lendingAssets: [{ ...defaultAssetData }],
  addresses: { ...defaultAddresses },

  weightedApy: { ...EMPTY_PERCENTAGE },
  weightedTvl: defaultValueInterface,
};

const defaultAccountData: AccountData = {
  permissionsMapByPoolId: { ...defaultPermissionsMapById },
};

const defaultContextData: ContextData = {
  ...defaultGlobalData,
  ...defaultAccountData,
  accountLoading: false,
  syrupPoolLoading: false,
  isUSDTEnabled: false,
  refetchAccount: () => null,
  refetchGlobal: () => null,
};

export const DataContext = createContext<ContextData>({ ...defaultContextData });

export interface DataProps {
  children: ReactNode;
}

export const DataProvider = ({ children }: DataProps): ReactElement => {
  const { account } = useContext(ClientContext);
  const { isUSDTEnabled } = useFeatureFlags();

  const [theGlobalData, setTheGlobalData] = useState<GlobalData>({ ...defaultGlobalData });
  const [theAccountData, setTheAccountData] = useState<AccountData>({ ...defaultAccountData });

  const {
    data: syrupPoolData,
    loading: syrupPoolLoading,
    refetch: refetchGlobal,
  } = useGetGlobalSyrupDataQuery({
    pollInterval: POLLING_INTERVAL,
    variables: { symbolNot: !isUSDTEnabled ? 'USDT' : '' },
  });

  const {
    data: accountData,
    loading: accountLoading,
    refetch: refetchAccount,
  } = useGetAccountQuery({
    variables: { id: account?.toLowerCase() ?? '' },
    skip: !account,
  });

  useEffect(() => {
    // loading
    if (syrupPoolLoading) return;

    // reset
    if (!syrupPoolData) {
      setTheGlobalData({ ...defaultGlobalData });
      return;
    }

    // init
    initGlobalData(syrupPoolData);
  }, [syrupPoolData, syrupPoolLoading]);

  useEffect(() => {
    // loading
    if (accountLoading || syrupPoolLoading) return;

    const unwatch = watchAccount(config, {
      onChange(account) {
        if (account.isConnecting) {
          setTheAccountData({ ...defaultAccountData });
        }
      },
    });

    // reset
    if (!accountData || !syrupPoolData || !account) {
      setTheAccountData({ ...defaultAccountData });
      return;
    }

    // init
    initAccount(accountData, syrupPoolData?.poolV2S);

    return () => {
      unwatch();
    };
  }, [accountLoading, syrupPoolLoading, account, accountData, syrupPoolData]);

  const initGlobalData = async (data: GetGlobalSyrupDataQuery) => {
    const syrupPools = data?.poolV2S as PoolFragment[];

    if (!syrupPools || !syrupPools[0]?.syrupRouter) {
      setTheGlobalData({ ...defaultGlobalData });
      return;
    }

    const weightedApy = buildPercentageValueInterfaceFromBigNumber(
      BigInt(data.syrupGlobals.apy),
      APY_DECIMALS,
      PERCENTAGE_UI_DECIMALS,
    );

    const weightedTvl = buildValueInterfaceFromBigNumberValues(BigInt(data.syrupGlobals.tvl), USDC_DECIMALS);

    const lendingAssets = data?.poolV2S
      .reduce((acc, { asset }) => {
        if (!asset) return acc;

        const assetIndex = acc.findIndex(({ id }) => id === asset.id);

        if (assetIndex === -1) {
          acc.push(asset);
        }

        return acc;
      }, [] as AssetFragment[])
      .sort((a, b) => a.symbol.localeCompare(b.symbol));

    const addresses = syrupPools.reduce((acc, { id, asset, syrupRouter }) => {
      if (!asset || !syrupRouter) return acc;

      const assetSymbol = asset.symbol.toLowerCase() as 'usdc' | 'usdt';

      acc[`pool${assetSymbol.toUpperCase()}` as Contracts] = id as Address;
      acc[`router${assetSymbol.toUpperCase()}` as Contracts] = syrupRouter.id as Address;
      acc[assetSymbol.toLowerCase() as Contracts] = asset.id as Address;

      return acc;
    }, {} as Record<Contracts, Address>);

    setTheGlobalData({
      pools: data?.poolV2S,
      lendingAssets,
      addresses,
      weightedApy,
      weightedTvl,
    });
  };

  const initAccount = async (accountData: GetAccountQuery, pools: PoolFragment[]) => {
    const permissionsMapByPoolId = pools.reduce((acc, { functionLevelBitmaps, permissionLevel, id }) => {
      const thePermissionMap = functionLevelBitmaps?.reduce(
        (acc, { functionId, bitmap }: FunctionLevelBitmapFragment) => {
          const hasPermissions = accountHasPermission({
            accountBitmap: accountData?.account?.permissionsBitmap || 0,
            functionBitmap: BigInt(bitmap),
            permissionLevel,
          });

          acc[functionId as FunctionIDs] = hasPermissions;

          return acc;
        },
        {} as Record<FunctionIDs, boolean>,
      );

      acc[id] = thePermissionMap;

      return acc;
    }, {} as PermissionsMapById);

    setTheAccountData({
      permissionsMapByPoolId,
    });
  };

  return (
    <DataContext.Provider
      value={{
        ...theGlobalData,
        ...theAccountData,
        accountLoading,
        syrupPoolLoading,
        isUSDTEnabled,
        refetchAccount,
        refetchGlobal,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
