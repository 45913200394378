import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

// Components
import ConnectWallet from 'Components/ConnectWallet';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/logo-syrup-full.svg';

// Hooks
import useScrollPosition from 'Hooks/useScrollPosition';

const wrapper = {
  backgroundColor: { xs: 'background.white', md: 'transparent' },
  position: 'fixed',
  width: '100vw',
  transform: theme => ({ xs: 'translateY(0px)', md: `translateY(${theme.spacing(2)})` }),
  left: 0,
  zIndex: 9, // @TODO set custom zIndex into theme
  transition: 'box-shadow 0.3s ease',
};
const wrapperShadow = {
  boxShadow: theme => ({ xs: theme.syrupShadows.xl, md: 'none' }),
};

const stack = {
  borderRadius: { xs: 0, md: '100px' },
  backgroundColor: 'background.white',
  width: '100%',
  py: 2.5,
  px: { xs: 0, md: 4 },
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
};
const stackShadow = {
  boxShadow: theme => ({ xs: 'none', md: theme.syrupShadows.xl }),
};

const logo = {
  overflow: 'hidden',
  height: '36px',
  width: { xs: '36px', md: '121px' },
  svg: { height: '36px', width: '121px' },
};

const AppBar = () => {
  const hasScrolled = useScrollPosition();

  return (
    <Box sx={{ ...wrapper, ...(hasScrolled && wrapperShadow) }}>
      <Container maxWidth='lg'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ ...stack, ...(hasScrolled && stackShadow) }}
        >
          <Link to='/'>
            <Box sx={logo}>
              <SyrupLogo />
            </Box>
          </Link>

          <ConnectWallet />
        </Stack>
      </Container>
    </Box>
  );
};

export default AppBar;
