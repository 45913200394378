import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { Connector, useAccount, useAccountEffect, useChains, useWalletClient } from 'wagmi';
import { waitForTransactionReceipt, WaitForTransactionReceiptParameters } from '@wagmi/core';
import { Address, Chain, PublicActions, publicActions, WalletClient, TransactionReceipt } from 'viem';
import SafeAppsSDK from '@safe-global/safe-apps-sdk';

// Hooks
import useIsGnosisSafe from 'Hooks/useIsGnosisSafe';
import { useAnalytics } from 'Hooks/useAnalytics';

// Constants
import { config } from 'Constants/network';
import { POLLING_INTERVAL } from 'Constants';

export type PublicActionsWithClientWallet = PublicActions & WalletClient & CustomActions;

type CustomActions = {
  getTransactionReceipt: (props: WaitForTransactionReceiptParameters) => Promise<TransactionReceipt>;
};

type ClientContext = {
  account: Address | undefined;
  connected: boolean | undefined;
  web3Client: PublicActionsWithClientWallet | undefined;
  chain: Chain | undefined;
  connector: string;
  connectorChainId?: number;
};

const defaultClientContext: ClientContext = {
  account: undefined,
  connected: undefined,
  web3Client: undefined,
  chain: undefined,
  connector: 'unknown',
  connectorChainId: undefined,
};

export const ClientContext = createContext<ClientContext>(defaultClientContext);

type Props = { children: ReactNode };

export const ClientProvider = ({ children }: Props) => {
  const [chain] = useChains();
  const { analytics } = useAnalytics();
  const { address, isConnected, connector } = useAccount();
  const [connectorChainId, setConnectorChainId] = useState<number | undefined>();
  const gnosisConnectionType = useIsGnosisSafe();
  const walletClient = useWalletClient();

  useAccountEffect({
    config,
    onConnect({ address, connector }) {
      const params = { account: address, connector: connector?.name };
      analytics?.identify(address, params);
      analytics?.track('Wallet Connected', params);
    },
    onDisconnect() {
      analytics?.track('Wallet Disconnected');
    },
  });

  const getConnectorChainId = async (connector: Connector) => {
    if (!connector?.getChainId) return;

    try {
      const chainId = await connector.getChainId();
      // console.log('ClientProvider', { chainId });
      setConnectorChainId(chainId);
    } catch (error) {
      console.error('ClientProvider', { error });
    }
  };

  useEffect(() => {
    if (connector) {
      // console.log('ClientProvider', { connector });

      getConnectorChainId(connector);
    }
  }, [connector]);

  const getTransactionHashFromSafe = async (safeTxHash: string) => {
    const safeApp = new SafeAppsSDK();
    const twoMinutesInMilliseconds = 1000 * 60 * 2;
    const amountOfRetriesInTwoMinutes = twoMinutesInMilliseconds / POLLING_INTERVAL;
    let retries = 0;

    while (retries < amountOfRetriesInTwoMinutes) {
      try {
        const { txHash } = await safeApp.txs.getBySafeTxHash(safeTxHash);

        if (txHash) return txHash;

        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
        retries++;
      } catch (err) {
        // Wait POLLING_INTERVAL (ie: 1500ms) before fetch data from gnosis safe API again
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
        retries++;
      }
    }

    return undefined;
  };

  const getTransactionReceipt = async ({ hash }: WaitForTransactionReceiptParameters) => {
    try {
      if (gnosisConnectionType === 'gnosis-default') {
        const transactionHashFromSafe = await getTransactionHashFromSafe(hash);
        const txReceipt = await waitForTransactionReceipt(config, { hash: transactionHashFromSafe as `0x${string}` });
        return txReceipt;
      }

      const txReceipt = await waitForTransactionReceipt(config, { hash });
      return txReceipt;
    } catch (error: unknown) {
      console.error(`👻 Client.getTransactionReceipt:${hash} :::`, { error });
      throw error;
    }
  };

  const web3Client = useMemo(() => {
    if (walletClient) {
      const { data } = walletClient;
      const client = data?.extend(publicActions);

      return client;
    }
  }, [walletClient]);

  useEffect(() => {
    // some wallets not avl when running locally, so is useful temporary to log the connector
    console.log('ClientProvider', { connector: connector?.id });
  }, [connector]);

  return (
    <ClientContext.Provider
      value={{
        web3Client: web3Client ? { ...web3Client, getTransactionReceipt } : undefined,
        account: address,
        connected: isConnected,
        chain,
        connector: connector?.id || 'unknown',
        connectorChainId,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
