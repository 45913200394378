import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

export const marketingNavItems = [
  {
    label: 'Docs',
    url: 'https://syrup.gitbook.io/syrup',
  },
  {
    label: 'FAQ',
    url: 'https://syrup.gitbook.io/syrup/troubleshooting-and-support/faq',
  },
];

const stack = {
  backgroundColor: 'transparent',
  py: { xs: 2, md: 0 },
};

const NavBar = () => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='center' spacing={10} sx={stack}>
      {marketingNavItems.map(({ label, url }) => (
        <Link
          key={label}
          to={url}
          target='_blank'
          rel='noopener noreferrer'
          style={{ textDecoration: 'none', color: 'none' }}
        >
          <MapleTypography variant='label' size='medium' color='text.strong'>
            {label}
          </MapleTypography>
        </Link>
      ))}
    </Stack>
  );
};

export default NavBar;
