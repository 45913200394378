import { useRef } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/logo-syrup-full.svg';
import { ReactComponent as SyrupLogoMobile } from 'Assets/logo-syrup-mobile.svg';
import { RiArrowRightLine } from '@remixicon/react';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MobileMenu from 'Pages/Home/MobileMenu';
import LandingNav from 'Pages/Home/LandingNav';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

const styles = {
  py: 4,
  position: 'absolute',
  px: { xs: 4.5, md: 7.5 },
  zIndex: 2,
  width: '100%',
};

const logo = {
  overflow: 'hidden',
  height: '36px',
};

const AppBarMarketing = () => {
  const { isDesktopDevice } = useBreakpoint();
  const containerRef = useRef(null);

  return (
    <Stack ref={containerRef} direction='row' justifyContent='center' sx={styles}>
      <Stack direction='row' justifyContent='space-between' sx={{ width: '100%', maxWidth: '1200px' }}>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
          {!isDesktopDevice && <MobileMenu containerRef={containerRef} />}
          <Link to='/'>
            <Box sx={logo}>{isDesktopDevice ? <SyrupLogo height='36px' width='121px' /> : <SyrupLogoMobile />}</Box>
          </Link>
        </Stack>

        {isDesktopDevice && <LandingNav />}

        <Link to='/lend' style={{ textDecoration: 'none' }}>
          <MapleButton size='small' endIcon={<RiArrowRightLine />}>
            Enter App
          </MapleButton>
        </Link>
      </Stack>
    </Stack>
  );
};

export default AppBarMarketing;
