export const isProtocolPaused = process.env.REACT_APP_PROTOCOL_PAUSED
  ? JSON.parse(process.env.REACT_APP_PROTOCOL_PAUSED)
  : false;

export const isUSDTEnabled = process.env.REACT_APP_USDT_ENABLED ? JSON.parse(process.env.REACT_APP_USDT_ENABLED) : true;

export const isReferralEnabled = process.env.REACT_APP_REFERRAL_ENABLED
  ? JSON.parse(process.env.REACT_APP_REFERRAL_ENABLED)
  : false;

export const isDripsEnabled = process.env.REACT_APP_DRIPS_ENABLED
  ? JSON.parse(process.env.REACT_APP_DRIPS_ENABLED)
  : false;
